import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=dbc58aa2&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/components/Icon.vue').default,Button: require('/app/components/Button.vue').default,Logo: require('/app/components/Logo.vue').default,Container: require('/app/components/Container.vue').default})
