//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('ui', ['darkMode']),
    logoURL() {
      return this.darkMode && this.$i18n.locale === 'en'
        ? '/rydale-logo-white.png'
        : '/logo.svg?data'
    },
  },
}
